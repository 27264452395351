import styled from '@emotion/styled';
import { MONO_SYSTEM_FONTS, SYSTEM_FONTS } from '../../styles/typography';

export const RecoletaHeading = styled.div({
  fontFamily: ['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', '),
  color: '#160b2c',
  fontWeight: 600,
});

export const OpenSansParagraph = styled.div({
  fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
  color: '#160b2c',
  fontWeight: 500,
  mixBlendMode: 'normal',
  opacity: '70%',
  letterSpacing: '0.4px',
});

export const HeroNewParagraph = styled.div({
  fontFamily: ['Hero New'].concat(SYSTEM_FONTS).join(', '),
  color: '#160b2c',
  fontWeight: 500,
  mixBlendMode: 'normal',
  opacity: '70%',
  letterSpacing: '0.4px',
});

export const MonoFontLabel = styled('span')({
  display: 'block',
  fontFamily: ['Source Code Pro'].concat(MONO_SYSTEM_FONTS).join(', '),
  fontWeight: 600,
  fontSize: '0.75rem',
  color: '#2AAB3C',
  letterSpacing: 1.5,
  marginBottom: '1rem',
});
